<template>
  <div class="dispose">
    <div class="dispose-main">
      <div class="dispose-tit">
        <div class="tit-left">首页配置</div>
        <el-button icon="el-icon-plus"
                   @click="showAddDispose">添加配置模块</el-button>
      </div>
      <div class="dispose-list">
        <transition-group name="flip-list">
          <div class="list-item"
               v-for="item in disposeList"
               :key="item"
               :draggable="true"
               @dragstart="onDragStart(item)"
               @dragenter="onDragenter(item)"
               @dragend="onDragEnd(item)">
            <div class="item-top-info">
              <div class="info-left">
                <div class="left-title">
                  <span class="title-line"></span>
                  <span class="title">{{item.title}}</span>
                </div>
                <div class="info-information">
                  <div class="information-item">
                    <span class="information-label">类型：</span>
                    <span class="information-cont">{{+item.media_type === 1 ? '视频' : '图片'}}</span>
                  </div>
                  <div class="information-item">
                    <span class="information-label">数量：</span>
                    <span class="information-cont">{{item.now_num}} / {{item.max_num}}</span>
                  </div>
                </div>
              </div>
              <div class="info-icon">
                <i class="iconfont icon2huizongmokuai"></i>
              </div>
            </div>
            <div class="item-btns">
              <el-button type="text"
                         @click="detail(item)">编辑</el-button>
              <el-button type="text"
                         @click="editDispose(item)">配置详情</el-button>
              <el-button type="text"
                         @click="delDispose(item)">删除</el-button>
              <el-button type="text"
                         @click="blockHide(item)">{{item.is_hide === 0 ? '隐藏' : '显示'}}</el-button>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <addDispose :disposeShow="disposeShow"
                :detailData="detailData"
                :preview="preview"
                @closeAdd="closeAdd"
                @addDisposeReq="addDisposeReq"
                @editDisposeReq="editDisposeReq"></addDispose>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent, getCurrentInstance, toRefs, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const addDispose = defineAsyncComponent(() => import('./components/addDispose.vue'))
export default defineComponent({
  components: {
    addDispose
  },
  props: {
  },
  setup (props, context) {
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    const data = reactive({
      disposeShow: false,
      detailData: {},
      preview: false,
      disposeList: [],
      oldVal: 0,
      newVal: 0
    })

    onMounted(() => {
      getList()
    })

    // 点击添加配置模块
    const showAddDispose = () => {
      data.disposeShow = true
    }
    // 关闭配置模块
    const closeAdd = () => {
      data.disposeShow = false
      data.detailData = {}
      data.preview = false
    }

    // 添加模块成功
    const addDisposeReq = () => {
      data.disposeShow = false
      data.preview = false
      getList()
    }

    const editDisposeReq = () => {
      data.disposeShow = false
      data.preview = false
      getList()
    }
    // 进入编辑详情
    const editDispose = (val) => {
      router.push({
        path: '/editSelection',
        query: {
          type: val.media_type,
          blockId: val.id,
          title: val.title
        }
      })
    }

    const detail = (val) => {
      data.disposeShow = true
      data.detailData = val
      data.preview = true
    }

    // 点击隐藏/显示按钮
    const blockHide = async (val) => {
      try {
        proxy.$api.postBlockHide({
          block_id: val.id,
          is_hide: val.is_hide === 0 ? 1 : 0
        })
        proxy.$notify({
          title: '系统通知',
          message: '操作成功',
          type: 'success',
          duration: 2000
        })
        setTimeout(() => {
          getList()
        }, 500)
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }

    // 删除模块
    const delDispose = async (val) => {
      try {
        proxy.$api.postBlockDel({
          block_id: val.id
        })
        proxy.$notify({
          title: '系统通知',
          message: '删除成功',
          type: 'success',
          duration: 2000
        })
        setTimeout(() => {
          getList()
        }, 500)
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }

    // 拖拽开始
    const onDragStart = (val) => {
      data.oldVal = val
    }

    // 拖拽
    const onDragenter = (val) => {
      data.newVal = val
    }
    // 拖拽结束
    const onDragEnd = (val) => {
      if (data.oldVal !== data.newVal) {
        const oldIndex = data.disposeList.indexOf(data.oldVal)
        const newIndex = data.disposeList.indexOf(data.newVal)
        const newItems = [...data.disposeList]
        // 删除老的节点
        newItems.splice(oldIndex, 1)
        // 增加新的节点
        newItems.splice(newIndex, 0, data.oldVal)
        data.disposeList = [...newItems]
        const blockIds = []
        data.disposeList.forEach(item => {
          blockIds.push(item.id)
        })
        blockSort(blockIds.join(','))
      }
    }

    // 获取列表
    const getList = async () => {
      try {
        const sd = await proxy.$api.getBlockList()
        data.disposeList = sd.data.list
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }

    // 拖拽排序
    const blockSort = async (sortIds) => {
      try {
        await proxy.$api.postBlockSort({
          sort_id: sortIds
        })
        proxy.$notify({
          title: '系统通知',
          message: '操作成功',
          type: 'success',
          duration: 2000
        })
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }
    return {
      ...toRefs(data),
      showAddDispose,
      detail,
      editDispose,
      closeAdd,
      onDragStart,
      onDragenter,
      onDragEnd,
      addDisposeReq,
      editDisposeReq,
      blockSort,
      blockHide,
      delDispose,
      getList
    }
  }
})
</script>

<style lang="scss" scoped>
.dispose {
  width: 100%;
  height: 100%;
  overflow: auto;
  .dispose-main {
    padding: 20px;
    .dispose-tit {
      display: flex;
      justify-content: space-between;
      .tit-left {
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        line-height: 40px;
      }
    }
    .dispose-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .list-item {
        width: 340px;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        .item-top-info {
          display: flex;
          justify-content: space-between;
          .info-left {
            width: 70%;
            .left-title {
              display: flex;
              .title-line {
                display: block;
                width: 4px;
                height: 16px;
                background: #ff4d4d;
                border-radius: 2px;
                margin-top: 4px;
                margin-right: 5px;
              }
              .title {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .info-information {
              padding-left: 10px;
              margin-top: 10px;
              .information-item {
                margin-bottom: 5px;
                span {
                  font-size: 14px;
                  color: #999999;
                }
              }
            }
          }
          .info-icon {
            width: 84px;
            height: 84px;
            background: #fefbfb;
            border-radius: 10px;
            position: relative;
            i {
              font-size: 50px;
              color: #ffd741;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        .item-btns {
          padding-left: 10px;
          margin-top: 20px;
        }
      }
    }
  }
}
.dispose-tit::v-deep(.el-button) {
  padding: 3px 10px;
  margin: 0;
  background-color: #ff4d4d;
  color: #fff;
  border: none;
}
.item-btns ::v-deep(.el-button) {
  color: #545f79;
  padding: 0;
  min-height: 0px;
  border-right: 1px solid #f8f8f8;
  padding-right: 10px;
  border-radius: 0px;
  &:hover {
    color: #1890ff;
  }
}
.flip-list-move {
  transition: transform 0.3s ease-in;
}
</style>
